<template>
  <div>
    <b-row class="product-statistics">
      <!--Time picker-->
      <b-col lg="6" sm="6">
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <VueCtkDateTimePicker class="w-100" range
                                    v-model="dateFilter"
                                    :locale="currentFormattedLang" :only-date="true" :open.sync="dateFilterOpen"
                                    format="YYYY-MM-DD" formatted="YYYY-MM-DD" no-header no-value-to-custom-elem
                                    @input="refresh"
              >
                <h3 class="mb-25 font-weight-bolder">
                  <b-link @click="dateFilterOpen = true">{{ dateFilterStr }}</b-link>
                </h3>
              </VueCtkDateTimePicker>
              <span>{{ msg('Period') }}</span>
            </div>
            <b-avatar variant="light-info" size="45">
              <i class="far fa-clock" style="font-size: 21px;"></i>
            </b-avatar>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="6" sm="6">
        <statistic-card-horizontal icon="CreditCardIcon" :statistic="statsTotal.count"
                                   :statistic-title="msg('Successful Payments')"/>
      </b-col>
      <b-col lg="6" sm="6">
        <statistic-card-horizontal icon="ShoppingBagIcon" color="success"
                                   :statistic-title="msg('Total amount')">
          <template slot="content-text">
            € {{statsTotal.total.toFixed(2)}}
          </template>
        </statistic-card-horizontal>
      </b-col>
      <b-col lg="6" sm="6">
        <statistic-card-horizontal icon="ProductIcon" color="warning" :statistic="soldProducts"
                                   :statistic-title="msg('Products sold')"/>
      </b-col>


      <!-- Payment methods graph-->
      <b-col cols="8">
        <b-card no-body style="min-height: 500px">
          <b-card-body>
            <div class="text-primary font-weight-bold mb-2" style="font-size: 18px;">
              {{msg("Payments") }}
            </div>
            <invoices-table :amount="10" v-bind:filter="invoiceFilterTable" :fields-filter="['clientId','amount','method']"/>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card no-body style="min-height: 500px">
          <b-card-body>
            <div class="text-primary font-weight-bold mb-2" style="font-size: 18px;">
              {{msg("Payment brand") }}
            </div>
            <div v-if="brandsGraph != null">
              <!-- chart -->
              <vue-apex-charts
                  id="payment methods-chart"
                  type="pie"
                  height="400"
                  :options="brandsGraph.options"
                  :series="brandsGraph.series"
              />
            </div>
            <div v-else class="text-center mt-5">
              <h3> <i class="fas fa-chart-pie"></i> No data</h3>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal'
import InvoicesTable from '@/views/monetary/invoice/invoices-table'
import {mapActions} from "vuex";
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'payments',
  components: {InvoicesTable,  StatisticCardHorizontal, VueApexCharts},
  data: () => ({
    dateFilter: {},
    dateFilterOpen: false,
    successfullPayments: 0,
    successfullOrders: 0,
    soldProducts: 0,
    orderStats: {},
    topProducts:[],
  }),
  created() {
    this.dateFilter.start = this.$moment().startOf('week').format('YYYY-MM-DD');
    this.dateFilter.end = this.$moment().endOf('week').format('YYYY-MM-DD');
    this.dateFilter.shortcut = "isoWeek";
    this.refresh()
  },
  computed: {
    dateShortcuts() {
      return [
        {
          key: 'thisWeek',
          label: 'This week',
          value: 'isoWeek'
        },
        {
          key: 'lastWeek',
          label: 'Last week',
          value: '-isoWeek'
        },
        {
          key: 'last7Days',
          label: 'Last 7 days',
          value: 7
        },
        {
          key: 'last30Days',
          label: 'Last 30 days',
          value: 30
        },
        {
          key: 'thisMonth',
          label: 'This month',
          value: 'month'
        },
        {
          key: 'lastMonth',
          label: 'Last month',
          value: '-month'
        },
        {
          key: 'thisYear',
          label: 'This year',
          value: 'year'
        },
        {
          key: 'lastYear',
          label: 'Last year',
          value: '-year'
        }
      ]
    },
    dateFilterStr() {
      if (this.dateFilter.start == null) {
        return this.msg('Today')
      }
      let shortcut = this.dateShortcuts.find(s => this.dateFilter.shortcut === s.value)
      if (shortcut != null) {
        return shortcut.label
      }

      let str = this.$moment(this.dateFilter.start)
          .format('l')
      if (this.dateFilter.end == null) {
        return str
      }

      return str + ' - ' + this.$moment(this.dateFilter.end)
          .format('l')
    },
    invoiceFilter(){
      let $this = this;
      return {lte:{creationDate:$this.dateFilter.end + " 00:00"}
        ,gte:{creationDate:$this.dateFilter.start + " 00:00"}}
    },
    invoiceFilterTable(){
     // filter: {like: {search: '', name: "", shortDescription: ""}, eq: {type: ""}, lte: {}, gte: {}},

      let $this = this;
      return {lte:{creationDate:$this.dateFilter.end + " 00:00"}
        ,gte:{creationDate:$this.dateFilter.start + " 00:00"}
        ,in:{status:["PAID","PAID_CASH"]}

      }
    },
    statsTotal() {
      return Object.values(this.orderStats).reduce((ans, stat) => {
        ans["count"] += stat["countTotal"]
        ans["total"] += stat["total"]
        return ans;
      }, {count: 0, total: 0})
    },
    graphColors(){
      return {
        brand:['#008FFB'],
        processor:['#008FFB', '#00E396', '#FF4560', '#FEB019', '#BF669B', '#1E6BB7']
      }
    },
    brandsGraph(){
      let processors = Object.values(this.orderStats).flatMap(s => s.processors)
      if(processors.length === 0){
        return null;
      }
      let brands = processors.reduce((obj,processor)=>{
        if(processor.cc_brands == null){
          return obj;
        }
        processor.cc_brands.forEach(brand=>{
          let name = (brand.cc_brand != null && brand.cc_brand !== "") ? brand.cc_brand : processor.processor;
          if(obj[name] == null){
            obj[name] = {label:name,total:0,count:0}
          }
          obj[name].total += brand.total;
          obj[name].count += brand.count;
        })
        return obj;
      },{})
      let brandsData = Object.values(brands).reduce((data,brand)=>{
        data.data.push(parseFloat(brand.total.toFixed(2)));
        data.label.push(`${brand.label} (${brand.count})`);
        return data;
      },{data:[],label:[]})
      if(brandsData.data.length === 0){
        return null;
      }
      return {
        series: brandsData.data,
        options: {
          chart: {
            type: 'pie',
          },
          labels: brandsData.label,
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      }
    },
    processorGraph() {
      if(true) return null; // DISABLED
      let $this = this;
      let processors = Object.values(this.orderStats).flatMap(s => s.processors)
      if(processors.length === 0){
        return null;
      }
      let data = processors.sort((p1,p2)=>p2.total-p1.total).reduce((ans, pro,index) => {
        ans.data.push(parseFloat(pro.total.toFixed(2)));
        ans.label.push(`${pro.processor} (${pro.countTotal})`);
        ans.color.push($this.graphColors.processor[index] || (`#${Math.floor(Math.random()*16777215).toString(16)}`))
        return ans
      }, {data: [], label: [],color:[]})
      if(data.data.length === 0){
        return null;
      }
      return {
        series: [
          {
            name:this.msg("Total"),
            data: data.data
          },
        ],
        options: {
          chart: {
            stacked: false,
            type: 'bar',
            toolbar: {show: true},
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: {show: true},
            },
          },
          colors: data.color,
          xaxis: {
            categories: data.label,
            labels: {
              style: {
                colors: data.color,
                fontSize: '12px'
              }
            }
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              columnWidth: '53%',
              distributed: true,

            },
            distributed: true,
          },
        }

      };
    },
/*
    topProductsGraph() {
      let $this = this;
      let data = this.topProducts.reduce((ans, pro,index) => {
        ans.data.push(parseFloat(pro.count.toFixed(2)));
        ans.label.push(`${pro.product.name} (${pro.count})`);
        ans.color.push($this.graphColors.processor[index] || (`#${Math.floor(Math.random()*16777215).toString(16)}`))
        return ans
      }, {data: [], label: [],color:[]})
      if(data.data.length === 0){
        return null;
      }
      return {
        series: [
          {
            name:this.msg("Total"),
            data: data.data
          },
        ],
        options: {
          chart: {
            stacked: false,
            type: 'bar',
            toolbar: {show: true},
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: {show: true},
            },
          },
          colors: data.color,
          xaxis: {
            categories: data.label,
            labels: {
              style: {
                colors: data.color,
                fontSize: '12px'
              }
            }
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: true,
            formatter: function (val,i) {
              return $this.topProducts[i.dataPointIndex].amount.toFixed(2)  + " €";
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          plotOptions: {
            bar: {
              columnWidth: '53%',
              distributed: true,

            },
            distributed: true,
          },
        }

      };
    }
*/
  },
  methods: {
    ...mapActions('monetary', ['getStats']),
    ...mapActions('order', ['getOrderStats','getOrderProductsStats']),

    refresh() {
      this.getSuccessfullPayments()
      this.getOrderStats({from: this.dateFilter.start, until: this.dateFilter.end})
          .then(this.onOrderStats)
     /* this.getOrderProductsStats({from: this.dateFilter.start, until: this.dateFilter.end})
          .then(this.onOrderProductsStats)*/
    },
    onOrderStats(stats){
      stats.forEach(s=>{
        if(s.method !== "CREDIT_CARD" && s.processors.length === 1){
          if(s.processors[0].processor == null||s.processors[0].processor === "")
            s.processors[0].processor = s.method.toLowerCase();
        }
      })
      this.orderStats = stats.toObject("method");
    },
   /* onOrderProductsStats(data){
      let products = (data["Products"] || []).toObject("id")
      this.topProducts = (data["TopProducts"] || []).map(p=>Object.assign(p,{"product":products[p.productId]})).slice(0,5)
    },*/
    getSuccessfullPayments() {
      let $this = this

      this.getStats({
        start: $this.dateFilter.start,
        end: $this.dateFilter.end
      }).then((ans) => {

        $this.successfullPayments = ans["payments"];
        $this.successfullOrders = ans["orders"];
        //$this.soldProducts = ans["products"];
      })
      //  return "start: "+ this.dateFilter.start + " end: "+ this.dateFilter.end;

    }
  }
}
</script>

<style scoped>

</style>
